@font-face {
  font-family: "CabinetGrotesk-Regular";
  src: url("./assets/fonts/CabinetGrotesk-Regular.woff2") format("woff2"),
    url("./assets/fonts/CabinetGrotesk-Regular.woff") format("woff"),
    url("./assets/fonts/CabinetGrotesk-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Medium";
  src: url("./assets/fonts/CabinetGrotesk-Medium.woff2") format("woff2"),
    url("./assets/fonts/CabinetGrotesk-Medium.woff") format("woff"),
    url("./assets/fonts/CabinetGrotesk-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Extrabold";
  src: url("./assets/fonts/CabinetGrotesk-Extrabold.woff2") format("woff2"),
    url("./assets/fonts/CabinetGrotesk-Extrabold.woff") format("woff"),
    url("./assets/fonts/CabinetGrotesk-Extrabold.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CabinetGrotesk-Black";
  src: url("./assets/fonts/CabinetGrotesk-Black.woff2") format("woff2"),
    url("./assets/fonts/CabinetGrotesk-Black.woff") format("woff"),
    url("./assets/fonts/CabinetGrotesk-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("./assets/fonts/SegoeUI-Bold.eot");
  src: local("./assets/fonts/Segoe UI Bold"), local("SegoeUI-Bold"),
    url("./assets/fonts/SegoeUI-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SegoeUI-Bold.woff2") format("woff2"),
    url("./assets/fonts/SegoeUI-Bold.woff") format("woff"),
    url("./assets/fonts/SegoeUI-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src: url("./assets/fonts/SegoeUI.eot");
  src: local("./assets/fonts/Segoe UI"), local("SegoeUI"),
    url("./assets/fonts/SegoeUI.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SegoeUI.woff2") format("woff2"),
    url("./assets/fonts/SegoeUI.woff") format("woff"),
    url("./assets/fonts/SegoeUI.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  background: #fafafa;
}

* {
  padding: 0;
  margin: 0;
  font-weight: normal;
  word-break: break-word;
  box-sizing: border-box;
}

.container {
  width: 85%;
  max-width: 1200px;
  margin: 0 auto;
}

.innerContainer {
  width: 90%;
  margin: 0 auto;
  max-width: 1400px;
}

.homePage {
  background: url(./assets/images/banner.png) no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
}

.homePageBanner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 14%;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.homePagelogin {
  background: #f1f1f1;
  border-radius: 10px;
  padding: 30px;
  max-width: 350px;
  width: 35%;
}

.homePagelogin h5 {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.36px;
  color: #29313d;
  font-family: "CabinetGrotesk-Regular";
}

.homePagelogin h4 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.038px;
  font-family: "CabinetGrotesk-Extrabold";
  color: #29313d;
  margin-bottom: 20px;
}

.homePagelogin a {
  border-radius: 40px;
  background: #09aec3;
  padding: 20px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: "CabinetGrotesk-Extrabold";
  margin-top: 30px;
  color: #f1f1f1;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0.064px;
  text-decoration: none;
  cursor: pointer;
}

.homePagelogin a img {
  margin-left: 12px;
}

.homePageContent h1 {
  font-size: 78px;
  line-height: 84px;
  color: #fff;
  font-family: "CabinetGrotesk-Black";
  margin-bottom: 30px;
}

.homePageContent {
  max-width: 540px;
  width: 55%;
}

.homePageContent h2 {
  font-size: 50px;
  line-height: 54px;
  color: #fff;
  margin-bottom: 50px;
  font-family: "CabinetGrotesk-Medium";
}

.homePageContent p {
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  font-family: "CabinetGrotesk-Regular";
}

/* .sideNav {
  width: 235px;
  min-width: 235px;
  padding: 20px;
  border-radius: 6px;
  border: 0.5px solid rgba(41, 49, 61, 0.2);
  background: #fff;
  position: relative;
} */
.sideNav {
  /* padding: 20px; */
  border-bottom: 0.5px solid rgba(41, 49, 61, 0.2);
  background: #fff;
  position: relative;
}

.sideNav ul li div {
  padding: 10px;
  border-radius: 4px;
  /* border: 1px solid rgba(19, 76, 141, 0.05); */
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.015px;
  color: #134c8d;
  font-family: "Segoe UI";
}

.sideNav ul li div img {
  margin-right: 12px;
}

.sideNav ul li div img:nth-child(2) {
  display: none;
}

.sideNav ul li div.Mui-selected img:first-child {
  display: none;
}

.sideNav ul li div.Mui-selected img:nth-child(2) {
  display: block;
}

.sideNav ul li div.Mui-selected {
  background: rgba(19, 76, 141, 0.05);
}

.sideNav ul li div:hover,
.sideNav ul li div.Mui-selected:hover {
  background: rgba(19, 76, 141, 0.05);
}

/* .sideNav ul li {
  padding: 0;
  margin-bottom: 10px;
} */
.sideNav ul li {
  padding: 0;
  margin-right: 10px;
  width: auto;
  margin-bottom: 10px;
}

/* .sideNav ul {
  height: calc(100vh - 190px);
  overflow: auto;
  padding: 0 0 20px;
} */
.sideNav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 5px 0 0;
}

.navUserLogo,
.sidenavLogoutIn {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.sidenavLogoutIn {
  border: 1px solid rgba(20, 77, 146, 0.1);
  border-radius: 6px;
  min-height: 64px;
  padding: 10px;
}

.navUserLogo {
  background: #f8f9fa;
  border: 1px solid rgba(20, 77, 146, 0.1);
  border-radius: 100%;
  color: #144d92;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  margin-right: 10px;
  text-transform: uppercase;
  width: 40px;
  min-width: 40px;
  font-family: "Segoe UI";
}

.navuserDtls {
  text-align: left;
}

.navuserDtls h4 {
  color: #144d92;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  font-family: "Segoe UI";
}


.navuserDtls h5 {
  color: #144d92;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Segoe UI";
}

.navuserDtls button {
  color: #78909c;
  display: block !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 4px 0 0;
  padding: 0;
  text-align: left;
  text-transform: none;
  font-family: "Segoe UI";
}

/* .sidenavLogo {
  margin-bottom: 30px;
} */

.headerTop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 10px;
}

.dashboardPage {
  min-height: calc(100vh - 204px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboardPage.marketingPage {
  min-height: calc(100vh - 270px);

}

.sidenavLogout {
  background: #fff;
  border-top: 1px solid rgba(20, 77, 146, 0.1);
  border-radius: 6px;
  /* bottom: 6px;
  left: 0;
  margin: 0 auto;
  padding-top: 10px;
  position: absolute;
  right: 0;
  width: calc(100% - 30px); */
}

.footer p {
  color: rgba(41, 49, 61, 0.4);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.056px;
  font-family: "Segoe UI";
  text-align: center;
}

.footer {
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top: 0.5px solid rgba(41, 49, 61, 0.20);
  margin-top: 10px;
}

.dataAnalyseBoard {
  padding: 10px 0;
  margin-bottom: 30px;
}

.dataBoard {
  /* padding-left: 20px; */
  width: 100%;
}

/* .powerbi-report {
  height: 85vh;
} */
.dataAnalyseBoard,
.dataAnalyseBoard .App,
.dataAnalyseBoard #bi-report,
.dataAnalyseBoard .powerbi-report {
  height: 100%;
}

.powerbi-report iframe {
  border: none;
}

/* CSS FOR CHAT GPT */

.chatdiv {
  position: relative;
  overflow: auto;
  height: 100%;
  padding: 0;
  background-color: #fff;
  /* position: fixed; */
  left: 0px;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  padding-top: 0;
  box-shadow: 1px -6px 12px rgba(0, 0, 0, 0.2);
}

.chatdiv {
  border-radius: 6px;
  border: 0.5px solid rgba(41, 49, 61, 0.20);
  background: #FFF;
  box-shadow: none;
  /* margin-top: 20px; */
}

.pointertextbox {
  pointer-events: none;
}

.closeChat {
  display: none;
  position: sticky;
  right: 10px;
  top: 10px;
  width: 30px;
  cursor: pointer;
  float: right;
  z-index: 1;
  background: #fff;
}

.chatBoxEnter button {
  position: absolute;
  border: none;
  outline: none;
  top: 51px;
  right: 40px;
  cursor: pointer;
  opacity: 0.7;
  background: transparent;
  text-align: center;
  padding: 0;
  width: 25px;
  height: 25px;
  border-radius: 2px;
}

.chatBoxEnter input {
  height: 70px;
  outline: none;
  line-height: 70px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 0 50px 0 20px;
  border-radius: 7px;
  font-size: 16px;
  color: #000;
  font-family: "Segoe UI";
}

.chatBoxEnter {
  position: absolute;
  /* width: calc(100% - 250px); */
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  height: 110px;
  background: transparent;
  padding: 28px 40px 0;
}

.chatMainBox {
  /* width: calc(100% - 250px); */
  width: 100%;
  position: relative;
  /* margin-left: 250px; */
  margin-left: 0px;
  box-sizing: border-box;
  min-height: 100%;
}

.chatHistory ul {
  padding: 0;
}

.chatBoxWrap {
  padding-top: 50px;
  padding-bottom: 20px;
  padding-left: 0;
  width: 100%;
  height: calc(100vh - 340px);
  overflow-y: auto;
  max-height: calc(100vh - 340px);
}

.chatBoxWrap .chatsent {
  padding: 25px 0;
}

.chatBoxWrap .chatsent {
  padding: 25px 40px;
  text-align: left;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "Segoe UI";
}

.chatsent.chatAns {
  background: #f7f7f8;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.chatBoxWrap .chatsent span {
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #ccc;
  border-radius: 5px;
  color: #000;
  margin-right: 10px;
  font-weight: 700;
}

.chatBoxWrap .chatsent p {
  width: calc(100% - 60px);
  margin: 0;
}

.chatIcon {
  position: fixed;
  cursor: pointer;
  bottom: 45px;
  right: 40px;
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.chatIcon:hover {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.chatsent.chatAns span {
  border: 1px solid rgba(41, 49, 61, 0.05);
  background: rgba(9, 174, 195, 0.05);
  color: #2357A4;
}

.chatsent.chatQ span {
  background: #2357a4;
  color: #fff;
}

.chatBody.open {
  overflow: hidden;
}

.chatHistory li {
  color: #fff;
  text-align: left;
  font-size: 15px;
  line-height: 18px;
  padding: 5px 3px;
  margin-bottom: 15px;
  border-radius: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-family: "Segoe UI";
}

.chatHistory li.active {
  background: #343541;
}

.chatHistory li:hover {
  background: #343541;
}

.chatHistorySidebar {
  position: absolute;
  background: #202123;
  left: 0;
  height: 100%;
  z-index: 2;
  width: 250px;
  padding: 10px;
  box-sizing: border-box;
  display: none;
}

.openHistory {
  position: sticky;
  left: 10px;
  top: 10px;
  width: 23px;
  cursor: pointer;
  float: left;
  z-index: 1;
  display: none;
}

.citation {
  color: #0058f3;
  text-decoration: underline;
}

#loader {
  height: 15px;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.MuiFormControl-root.MuiTextField-root fieldset {
  display: none;
}

@media (max-width: 767px) {
  .chatBoxEnter {
    width: 100%;
    left: 0;
  }

  .openHistory {
    display: block;
  }

  .chatHistorySidebar {
    display: none;
  }

  .chatMainBox {
    width: 100%;
    position: relative;
    margin-left: 0;
    box-sizing: border-box;
  }

  .chatBoxWrap .chatsent {
    padding: 25px;
  }

  .chatBoxEnter {
    width: 100%;
    left: 0;
  }

  .chatBoxEnter input {
    width: calc(100% - 30px);
  }

  .chatBoxEnter button {
    left: auto;
    right: 30px;
  }
}

.blinking-cursor {
  margin-left: 5px;
  font-weight: bold;
  font-size: 14px;
  animation: blink 1s infinite;
  background-color: #000;
}

@keyframes blink {

  0%,
  50% {
    opacity: 1;
  }

  50.1%,
  100% {
    opacity: 0;
  }
}

.loader-container {
  position: fixed;
  width: 90%;
  height: 70%;
  background-color: #fff;
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}


.sideNav.subMenu ul {
  border-radius: 6px;
  border: 0.5px solid rgba(41, 49, 61, 0.20);
  background: #FFF;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);
  padding: 0;
}

.sideNav.subMenu ul li {
  margin-bottom: 0;
}

.sideNav.subMenu {
  border: none;
  background: transparent;
  margin-top: 20px;
}

.dashboardPage.chatGptDataPage {
  padding-top: 20px;
}



.chatBoxTable {
  width: 100%;
}

.chatBoxTable table {
  border-collapse: collapse;
  width: 100%;
}

.chatBoxTable table td,
.chatBoxTable table th {
  border: 1px solid #ddd;
  padding: 12px;
  font-family: "Segoe UI";
  font-weight: 400;
  color: #134c8d;
  font-size: 16px;
}

.chatBoxTable table tr:nth-child(even) {
  background-color: rgba(19, 76, 141, 0.05);
}

.chatBoxTable table tr:hover {
  background-color: rgba(19, 76, 141, 0.05);
}

.chatBoxTable table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #134c8d;
  color: #fff;
  font-family: "Segoe UI";
  font-weight: 600;
}

.chatInitialIn h2 {
  display: block;
  width: 100%;
  text-align: center;
  font-family: "Segoe UI";
  font-weight: 400;
  color: #a9a9a9;
  margin-top: 12px;
  font-size: 20px;
  line-height: 28px;
}

.chatInitial {
  padding: 0 40px;
  height: 100%;
}

.chatInitialIn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.chatInitialIn img {
  width: 80px;
  opacity: 0.6;
}

.ChatPromptBtns button {
  border: 1px solid #d4d6d8;
  width: 49%;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-transform: inherit;
  color: #000;
  border-radius: 8px;
  padding: 15px 12px;
  margin-top: 10px;
}

.ChatPromptBtns {
  position: -webkit-sticky;
  position: sticky;
  top: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.loaderText {
  text-align: center;
  padding-bottom: 10px;
font-family: 'Segoe UI';
  color: #29313d;
  font-size: 14px;
  font-weight: 500;
}